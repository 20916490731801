export default {
  details: {
    title: "Codeho Web & App Architecture",
    tagLine: "Modern Full Stack Web and Mobile Applications",
    description:
      "Modern Full Stack Web and Mobile Applications. Thorsten Schau",
    url: "https://codeho.net",
  },
  assets: {
    avatar: "/images/common/codeho-logo.png",
    favicon: "/images/common/codeho-logo.svg",
  },
  socialLinks: {
    twitter: "@amazon",
  },
};
